import React from "react";
import moment from "moment";
import './Events.css';

export const MimEvent = (props) => {
    return (<>
        {props.data ? (
        <div className="row row-eq-height mb-3 row-borders-events font-events" style={props.rowColor ? {backgroundColor: props.rowColor }: null}>
            <div className="col-3 vertical-centered-cols-events">
                <div className="row">
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Local:</span> {moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                </div>
            </div>
            <div className="col-3 vertical-centered-cols-events">
                <div className="row">
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Sensor ID:</span> {props.data.id}
                    </div>
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Sensor Type:</span> {props.data.fieldDataProducer}
                    </div>
                </div>
            </div>
            <div className="col-6">
                <span style={{fontWeight: 'bold'}}>Gas Detection</span> &ensp;
                <div className="row">
                    <div className="col-sm-4">
                        <span style={{fontWeight: 'bold'}}>Gas Value: </span> &ensp;
                        {props.data.gasDetection[0].gasValue}
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Gas Mass:</span> &ensp;
                        {props.data.gasDetection[0].gasMass}
                    </div>
                    <div className="col-sm-4">
                        <span style={{fontWeight: 'bold'}}>Gas Value: </span> &ensp;
                        {props.data.gasDetection[1].gasValue}
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Gas Mass:</span> &ensp;
                        {props.data.gasDetection[1].gasMass}
                    </div>
                    <div className="col-sm-4">
                        <span style={{fontWeight: 'bold'}}>Gas Value: </span> &ensp;
                        {props.data.gasDetection[2].gasValue}
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Gas Mass:</span> &ensp;
                        {props.data.gasDetection[2].gasMass}
                    </div>
                </div>
            </div>
        </div>
        ) :  null}
    </>)
 }