import axios from "axios";

//Digital ocean droplets IPs
export const authInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/",
});
authInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const OrgInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/organizations/",
});
OrgInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const UserInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/users/",
});
UserInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const CustomDashboardInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/custom-dashboard/dashboard-component/",
});
CustomDashboardInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const InciInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/incidents/",
});
InciInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const ChatHistoryInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/chat/",
});
ChatHistoryInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const patInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/triage/",
});
patInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const patSiteInstance = axios.create({
  baseURL: "https://platform-back.search-and-rescue.eu/api/triage/physio-dss/incident/",
});
patSiteInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwtToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export const AnalInstance = axios.create({
  baseURL: "http://165.22.77.92:2020/analytics",
});

export const AnalAPIKey = "6f249b9e850b8a513643f33ad75ba37b";

export const GoogleAPIKey = "AIzaSyBi3YkRpos0ZvTIPv9R-MUMlCP7W7ETyyQ";

// // // Locali
// export const authInstance = axios.create({
//   baseURL: "http://192.168.2.19:9100/api/v1"
// });

// export const OrgInstance = axios.create({
//   baseURL: "http://192.168.2.19:8200/api/v1"
// });

// export const InciInstance = axios.create({
//   baseURL: "http://192.168.2.19:8300/api/v1"
// });

// Dss Middleware API
export const dssMiddleware = axios.create({
  baseURL: "https://dss-middleware.search-and-rescue.eu/api/",
});
dssMiddleware.interceptors.request.use(function (config) {
  const token = localStorage.getItem("dssToken");
  config.headers.Authorization = token ? `${token}` : "";
  return config;
});
