/* eslint-disable default-case */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { authInstance } from "../../../config";
import DataTable from "react-data-table-component";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import searchIcon from "../../../assets/images/search.svg";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { EventFilteringInput } from "./EventFilteringInput";
import "./EventLogPage.css";
import { SmartwatchEvent } from "./EventComponents/SmartwatchEvent";
import { GpsEvent } from "./EventComponents/GpsEvent";
import { EmgEvent } from "./EventComponents/EmgEvent";
import { StrainEvent } from "./EventComponents/StrainEvent";
import { EcgEvent } from "./EventComponents/EcgEvent";
import { MimEvent } from "./EventComponents/MimEvent";
import { SixGasEvent } from "./EventComponents/SixGasEvent";
import { XrayEvent } from "./EventComponents/XrayEvent";
import ReactPaginate from "react-paginate";
import { HealthMonitoringEvent } from "./EventComponents/HealthMonitoringEvent";

export const EventLogPage = (props) => {
  // debugger;
  const [allEvents, setAllEvents] = useState([]);
  const [smartEvents, setSmartEvents] = useState([]);
  const [gpsEvents, setGpsEvents] = useState([]);
  const [emgEvents, setEmgEvents] = useState([]);
  const [ecgEvents, setEcgEvents] = useState([]);
  const [mimsEvents, setMimsEvents] = useState([]);
  const [strainEvents, setStrainEvents] = useState([]);
  const [sixGasEvents, setSixGasEvents] = useState([]);
  const [xrayEvents, setXrayEvents] = useState([]);
  const [healthMonitoringEvents, setHealthMonitoringEvents] = useState([]);
  const [currentPageEvents, setCurrentPageEvents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [tableDataType, setTableDataType] = useState("ALL-DATA");
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [itemsOnPage, setItemsOnPage] = useState(10);
  const [refreshPaginator, setRefreshPaginator] = useState(0);

  const getEvents = useCallback(async () => {
    let [
      smartEventsResponse,
      gpsEventsResponse,
      emgEventsResponse,
      strainEventsResponse,
      ecgEventsResponse,
      mimsEventsResponse,
      sixGasEventsResponse,
      xrayEventsResponse,
      healthMonitoringResponse,
    ] = await Promise.all([
      authInstance.get("events/event/smartwatch/"),
      authInstance.get("events/event/gps_tracker/"),
      authInstance.get("events/event/emg/"),
      authInstance.get("events/event/strain/"),
      authInstance.get("events/event/ecg/"),
      authInstance.get("events/mims/"),
      authInstance.get("events/event/six_gas_hazmat/"),
      authInstance.get("events/event/xray"),
      authInstance.get("events/event/health_monitoring_device/"),
    ]);

    setSmartEvents(smartEventsResponse.data.data.smartwatch.part_1);
    setGpsEvents(gpsEventsResponse.data.data.gps_tracker.part_1);
    setEcgEvents(ecgEventsResponse.data.data.ecg.part_1);
    setEmgEvents(emgEventsResponse.data.data.emg.part_1);
    setStrainEvents(strainEventsResponse.data.data.strain.part_1);
    setMimsEvents(mimsEventsResponse.data.data);
    setSixGasEvents(sixGasEventsResponse.data.data.six_gas_hazmat.part_1);
    setXrayEvents(xrayEventsResponse.data.data.xray.part_1);
    setHealthMonitoringEvents(healthMonitoringResponse.data.data.health_monitoring_device.part_1);

    const allEventsData = smartEventsResponse.data.data.smartwatch.part_1
      .concat(gpsEventsResponse.data.data.gps_tracker.part_1)
      .concat(ecgEventsResponse.data.data.ecg.part_1)
      .concat(emgEventsResponse.data.data.emg.part_1)
      .concat(strainEventsResponse.data.data.strain.part_1)
      .concat(mimsEventsResponse.data.data)
      .concat(sixGasEventsResponse.data.data.six_gas_hazmat.part_1)
      .concat(xrayEventsResponse.data.data.xray.part_1)
      .concat(healthMonitoringResponse.data.data.health_monitoring_device.part_1);

    console.log("ALL EVENT DATA", allEventsData);

    allEventsData.sort((x, y) => {
      return y.localTimestamp - x.localTimestamp;
    });

    setAllEvents(allEventsData);
    initializePagesAndData(allEventsData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getEvents();
  }, []);

  const filterEvents = async (eventType, inputValue, inputType) => {
    let response;
    let filteredDataCustom;
    // eslint-disable-next-line default-case
    switch (eventType) {
      case "smartwatch":
        response = await authInstance.get("events/event/smartwatch/");
        filteredDataCustom = response.data.data.smartwatch.part_1
          .sort((x, y) => y.localTimestamp - x.localTimestamp)
          .slice(0, inputValue);
        console.log(filteredDataCustom);
        setSmartEvents(filteredDataCustom);
        setTableDataType("SMARTWATCH");
        initializePagesAndData(filteredDataCustom);
        break;
      case "gps":
        response = await authInstance.post("events/event/gps_by_number/", { number: inputValue });
        setGpsEvents(response.data.data);
        setTableDataType("GPS");
        break;
      case "ecg":
        response =
          inputType === "number"
            ? await authInstance.post("events/event/ecg_by_number/", { number: inputValue })
            : await authInstance.post("events/event/ecg_by_heartrate/", {
                min: parseInt(inputValue[0]),
                max: parseInt(inputValue[1]),
              });
        setEcgEvents(response.data.data);
        setTableDataType("ECG");
        break;
      case "emg":
        response = await authInstance.get("events/event/emg/");
        filteredDataCustom = response.data.data.emg.part_1
          .sort((x, y) => y.localTimestamp - x.localTimestamp)
          .slice(0, inputValue);
        setEmgEvents(filteredDataCustom);
        setTableDataType("EMG");
        initializePagesAndData(filteredDataCustom);
        break;
      case "strain":
        response = await authInstance.get("events/event/strain/");
        filteredDataCustom = response.data.data.strain.part_1
          .sort((x, y) => y.localTimestamp - x.localTimestamp)
          .slice(0, inputValue);
        setStrainEvents(filteredDataCustom);
        setTableDataType("STRAIN");
        initializePagesAndData(filteredDataCustom);
        break;
      case "rescue mims":
        response = await authInstance.get("events/mims/");
        filteredDataCustom = response.data.data
          .sort((x, y) => y.localTimestamp - x.localTimestamp)
          .slice(0, inputValue);
        setMimsEvents(filteredDataCustom);
        setTableDataType("MIMS");
        initializePagesAndData(filteredDataCustom);
        break;
      case "six gas hazmat":
        response = await authInstance.post("events/event/six_gas_hazmat_by_number/", {
          number: inputValue,
        });
        setSixGasEvents(response.data.data);
        setTableDataType("SIX-GAS-HAZMAT");
        break;
      case "xray":
        response = await authInstance.get("events/event/xray");
        filteredDataCustom = response.data.data.xray.part_1
          .sort((x, y) => y.localTimestamp - x.localTimestamp)
          .slice(0, inputValue);
        setXrayEvents(filteredDataCustom);
        setTableDataType("XRAY");
        initializePagesAndData(filteredDataCustom);
        break;
      case "health monitoring":
        response = await authInstance.get("events/event/health_monitoring_device/");
        filteredDataCustom = response.data.data.health_monitoring_device.part_1
          .sort((x, y) => y.localTimestamp - x.localTimestamp)
          .slice(0, inputValue);
        setHealthMonitoringEvents(filteredDataCustom);
        setTableDataType("HEALTH-MONITORING");
        initializePagesAndData(filteredDataCustom);
        break;
      case "all events":
        await getEvents();
        setTableDataType("ALL-DATA");
    }

    selectFirstPage();

    if (
      eventType !== "all events" &&
      eventType !== "strain" &&
      eventType !== "emg" &&
      eventType !== "smartwatch" &&
      eventType !== "rescue mims" &&
      eventType !== "xray" &&
      eventType !== "health monitoring"
    ) {
      initializePagesAndData(response.data.data);
    }
  };

  // paginator functions
  const initializePagesAndData = (displayedData) => {
    const pagesValue = Math.ceil(displayedData.length / 10);
    if (pagesValue > 0) {
      setCurrentPageEvents(displayedData.slice(0, itemsOnPage));
    } else {
      setCurrentPageEvents(displayedData);
    }
    setNumberOfPages(pagesValue);
  };

  const onPageChange = (event) => {
    const selectedPage = event.selected + 1;

    let currentPage;

    switch (tableDataType) {
      case "ALL-DATA":
        currentPage = allEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "SMARTWATCH":
        currentPage = smartEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "GPS":
        currentPage = gpsEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "ECG":
        currentPage = ecgEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "EMG":
        currentPage = emgEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "STRAIN":
        currentPage = strainEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "MIMS":
        currentPage = mimsEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "SIX-GAS-HAZMAT":
        currentPage = sixGasEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "XRAY":
        currentPage = xrayEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
      case "HEALTH-MONITORING":
        currentPage = healthMonitoringEvents.slice(
          selectedPage * itemsOnPage - itemsOnPage,
          selectedPage * itemsOnPage
        );
        break;
    }

    setCurrentPageEvents(currentPage);
  };

  const selectFirstPage = () => {
    setRefreshPaginator(refreshPaginator + 1);
  };

  return (
    <div className="resourceMGTTables">
      {console.log(currentPageEvents)}
      {isLoading ? (
        <p className="loading">Waiting for data</p>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <FontAwesomeIcon
                icon={faFileAlt}
                size="lg"
                className="fontawesome-dash"
                style={{ float: "left" }}
              />
              <h1 className="dash-heading" style={{ float: "left" }}>
                Event Log
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <EventFilteringInput filterEvents={filterEvents} />
            </div>
          </div>

          <div className="row">
            <div className="col-3">Time</div>
            <div className="col-3">Source</div>
            <div className="col-6">Activity</div>
          </div>

          {currentPageEvents.map((eventData, index) => {
            switch (eventData?.name) {
              case "smartwatch":
                return (
                  <SmartwatchEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "gps_tracker":
                return (
                  <GpsEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "emg_device":
                return (
                  <EmgEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "strain_device":
                return (
                  <StrainEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "ecg_device":
                return (
                  <EcgEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "rescue_mims":
                return (
                  <MimEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "six_gas_hazmat":
                return (
                  <SixGasEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "xray_device":
                return (
                  <XrayEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
              case "health_monitoring_device":
                return (
                  <HealthMonitoringEvent
                    key={index}
                    rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                    data={eventData}
                  />
                );
            }
          })}

          <div className="row">
            <div className="col-12">
              <ReactPaginate
                key={refreshPaginator}
                onPageChange={onPageChange}
                pageRangeDisplayed={5}
                pageCount={numberOfPages}
                nextLabel="next >"
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
              ></ReactPaginate>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventLogPage);
