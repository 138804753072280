import React, { useEffect, useState, useRef } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import "./EventFilteringInput.css";

export const EventFilteringInput = (props) => {
  const [selectedFilterAndInput, setSelectedFilterAndInput] = useState({
    selectedFilter: "all events",
  });
  const [disabledInput, setDisabledInput] = useState();
  const [isButtonDisabled, setIsDisabledButton] = useState(false);

  const numberInputRef = useRef(null);
  const rangeInputRefMin = useRef(null);
  const rangeInputRefMax = useRef(null);

  const onSelectFilter = (event) => {
    setSelectedFilterAndInput({ selectedFilter: event });
    if (event === "all events") {
      resetInputs(false);
    } else {
      resetInputs();
    }
  };

  const onChangeInput = (event) => {
    let inputType;
    let inputValue;
    if (selectedFilterAndInput.selectedFilter === "ecg") {
      inputType = disabledInput === "number" ? "range" : "number";
      inputValue =
        disabledInput === "number"
          ? [rangeInputRefMin.current.value, rangeInputRefMax.current.value]
          : numberInputRef.current.value;
    } else {
      inputType = "number";
      inputValue = numberInputRef.current.value;
    }

    setSelectedFilterAndInput({
      ...selectedFilterAndInput,
      inputValue: inputValue,
      inputType: inputType,
    });

    if (inputValue) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
    // only for ecg event type
    if (numberInputRef.current && rangeInputRefMin.current && rangeInputRefMax.current) {
      toggleDisableStatus();
    }
  };

  const toggleDisableStatus = () => {
    if (numberInputRef.current.value) {
      setDisabledInput("range");
    } else if (rangeInputRefMin.current.value || rangeInputRefMax.current.value) {
      setDisabledInput("number");
    } else {
      setDisabledInput(null);
    }
  };

  const resetInputs = (isButtonDisabled = true) => {
    setDisabledInput(null);
    if (numberInputRef.current) {
      numberInputRef.current.value = null;
    }
    setIsDisabledButton(isButtonDisabled);
  };

  const renderInputs = () => {
    if (selectedFilterAndInput.selectedFilter) {
      if (selectedFilterAndInput.selectedFilter === "ecg") {
        return (
          <>
            <div className="col-12 mb-3">
              <Form.Control
                ref={numberInputRef}
                type="number"
                placeholder="Give number of results"
                onChange={onChangeInput}
                disabled={disabledInput === "number"}
              />
            </div>
            <div className="col-5 mb-3">
              <Form.Label>Minimum Heart Rate</Form.Label>
              <Form.Control
                ref={rangeInputRefMin}
                type="range"
                value={rangeInputRefMin.current?.value ? rangeInputRefMin.current.value : "50"}
                min="0"
                max="200"
                step="1"
                onChange={onChangeInput}
                disabled={disabledInput === "range"}
              />
              {rangeInputRefMin.current?.value ? rangeInputRefMin.current.value : "50"}
            </div>
            <div className="col-5 mb-3">
              <Form.Label>Maximum Heart Rate</Form.Label>
              <Form.Control
                ref={rangeInputRefMax}
                type="range"
                value={rangeInputRefMax.current?.value ? rangeInputRefMax.current.value : "100"}
                min="0"
                max="200"
                step="1"
                onChange={onChangeInput}
                disabled={disabledInput === "range"}
              />
              {rangeInputRefMax.current?.value ? rangeInputRefMax.current.value : "100"}
            </div>
            <div className="col-2 text-center" style={{ marginTop: "auto", marginBottom: "46px" }}>
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faRedo} onClick={resetInputs} />
            </div>
          </>
        );
      } else if (selectedFilterAndInput.selectedFilter === "all events") {
        return null;
      } else {
        // any other case
        return (
          <div className="col-12 mb-3">
            <Form.Control
              ref={numberInputRef}
              type="number"
              placeholder="Give number of results"
              onChange={onChangeInput}
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedFilterAndInput.selectedFilter}
          onSelect={onSelectFilter}
        >
          <Dropdown.Item eventKey="all events">All Events</Dropdown.Item>
          <Dropdown.Item eventKey="smartwatch">Smartwatch</Dropdown.Item>
          <Dropdown.Item eventKey="gps">Gps</Dropdown.Item>
          <Dropdown.Item eventKey="ecg">Ecg</Dropdown.Item>
          <Dropdown.Item eventKey="emg">Emg</Dropdown.Item>
          <Dropdown.Item eventKey="strain">Strain</Dropdown.Item>
          <Dropdown.Item eventKey="rescue mims">Rescue Mims</Dropdown.Item>
          <Dropdown.Item eventKey="six gas hazmat">Six Gas Hazmat</Dropdown.Item>
          <Dropdown.Item eventKey="xray">X-ray</Dropdown.Item>
          <Dropdown.Item eventKey="health monitoring">Health Monitoring</Dropdown.Item>
        </DropdownButton>
      </div>
      {renderInputs()}
      <div className="col-6 offset-6">
        <Button
          style={
            !isButtonDisabled
              ? { width: "100%", margin: "0" }
              : { width: "100%", margin: "0", cursor: "not-allowed" }
          }
          className="custom-btn"
          disabled={isButtonDisabled}
          onClick={() =>
            props.filterEvents(
              selectedFilterAndInput.selectedFilter,
              selectedFilterAndInput.inputValue,
              selectedFilterAndInput.inputType
            )
          }
        >
          Search Events
        </Button>
      </div>
    </div>
  );
};
